export interface ReleaseNote {
  _id: string
  startAt?: string
  endAt?: string
  title: string
  content: string
}

export const useReleaseNoteStore = defineStore("releaseNote", () => {
  /** @deprecated */
  const releaseNotes = ref<Array<ReleaseNote>>([])

  const getReleaseNotes = async (pagination = false, date?: string) => {
    const currentDate = new Date().toISOString()

    return useCrudMethods<ReleaseNote>("/release-notes", releaseNotes).readItems({
      pagination,
      query: JSON.stringify({
        $and: [
          {
            startAt: date && { $gte: date }
          }, {
            startAt: { $lte: currentDate }
          }, {
            $or: [{ endAt: { $exists: false } }, { endAt: { $gte: currentDate } }]
          }
        ]
      }),
    })
  }

  const getReleaseNotesById = async (id: ReleaseNote["_id"]) => useCrudMethods<ReleaseNote>("/release-notes", releaseNotes).readItemById(id)
  const updateReleaseNote = async (id: ReleaseNote["_id"], item: Partial<ReleaseNote>) =>
    useCrudMethods<ReleaseNote>("/release-notes", releaseNotes).updateItem(id, item)
  const postReleaseNote = async (item: Partial<ReleaseNote>) => useCrudMethods<ReleaseNote>("/release-notes", releaseNotes).createItem(item)
  const deleteReleaseNote = async (id: ReleaseNote["_id"]) => useCrudMethods<ReleaseNote>("/release-notes", releaseNotes).deleteItem(id)

  return {
    releaseNotes,

    getReleaseNotes,
    getReleaseNotesById,
    updateReleaseNote,
    postReleaseNote,
    deleteReleaseNote,
  }
})
